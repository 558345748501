import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { Routes, RouterModule, Router } from '@angular/router';

import { PageNotFoundComponent } from './external/page-not-found/page-not-found.component';
import { ApiService } from './services/api.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NotificationService } from './services/notification.service';
import { InterceptorService } from './services/interceptor.service';
import { AppLoadService } from './services/app-load.service';
import { LoaderComponent } from './common/loader/loader.component';
import { ToastrModule } from 'ngx-toastr';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TicketService } from './services/ticket.service';
import { SocketIoModule } from 'ngx-socket-io';
import { ModalModule } from 'ngx-bootstrap/modal';

import './global';
import { SocketsService } from './services/sockets/sockets.service';
import { ApiCatalogService } from './services/apiCatalog.service';
import { ReportService } from './services/report.service';
import { ApiPatientReorder } from './services/apiPatientReorder.service';
import { ToastComponent } from './toast/toast.component';
import { ToastService } from './toast/toast.service';
import { NgxPrintModule } from 'ngx-print';
export function initializeApp(appInitService: AppLoadService) {
  return (): Promise<any> => {
    return appInitService.init().then(
      (val) => {
        console.log(val);
      },
      (err) => {
        console.error(err);
      }
    );
  }
}

const routes: Routes = [
  {
    path: 'error', component: PageNotFoundComponent
  },
  {
    path: '', loadChildren: () => import('./internal/internal.module').then(m => m.InternalModule), data: { preload: false, delay: false }
  },
  {
    path: '', loadChildren: () => import('./external/external.module').then(m => m.ExternalModule), data: { preload: false, delay: false }
  },
  {
    path: '**', component: PageNotFoundComponent
  }
];

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    ToastComponent,    
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    ModalModule.forRoot(),
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    TimepickerModule.forRoot(),
    SocketIoModule,
    NgxPrintModule
  ],
  providers: [
    ApiService,
    ApiCatalogService,
    ApiPatientReorder,
    TicketService,
    NotificationService,
    ReportService,
    // SocketConnection,
    SocketsService,
    ToastService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppLoadService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
