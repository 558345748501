HTMLTextAreaElement.prototype.insertAtCaret = insertAtCaret;
interface HTMLTextAreaElement {
    insertAtCaret: typeof insertAtCaret;
}
function insertAtCaret(text) {
    text = text || '';
    if (this.selectionStart || this.selectionStart === 0) {
      // Others
      var startPos = this.selectionStart;
      var endPos = this.selectionEnd;
      this.value = this.value.substring(0, startPos) +
        text +
        this.value.substring(endPos, this.value.length);
      this.selectionStart = startPos + text.length;
      this.selectionEnd = startPos + text.length;
    } else {
      this.value += text;
    }
}

