import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  apiEndpoint:String = "";

  constructor(
    private http:HttpClient,
  ) { 
    this.apiEndpoint = environment.TICKET_END_POINT;
  }

  getData(url,param)
  {
    return this.http.get<any>(this.apiEndpoint+url,{params:param});
  }

  postData(url,value)
  {
    Object.keys(value).forEach(key => {
      if(!value[key] || value[key] === undefined )
      delete value[key];
    });
    return this.http.post<any>(this.apiEndpoint+url,value);
  }
  
  putData(url,value)
  {
    return this.http.put<any>(this.apiEndpoint+url,value);
  }

  patchData(url,value)
  {
    return this.http.patch<any>(this.apiEndpoint+url,value);
  }

  deleteData(url,param)
  {
    return this.http.delete<any>(this.apiEndpoint+url,{params:param});
  }

  errorHandler(err){
    console.log(err);
  }

    
}
