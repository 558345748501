import { Component, OnInit } from '@angular/core';
import { ToastService } from './toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent implements OnInit {

  toasts:any[]=[];

  constructor(
    private ts:ToastService
  ) { 
    this.toasts = ts.toasts;
  }

  ngOnInit(): void {
  }

}
