import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  console.log(`
####  #  #   ##   #  #   ##   #   #   #   ##       #   #  #####  #####   ##   #     #                                                                
#     #  #  #  #  # #   #  #  #    # #   #  #      #   #    #      #    #  #  # # # #                                                        
####  ####  ####  ##    ####  #     #    ####      #   #    #      #    ####  #  #  #                                                              
   #  #  #  #  #  # #   #  #  #     #    #  #      #   #    #      #    #  #  #     #                                                                   
####  #  #  #  #  #  #  #  #  ####  #    #  #       ###     #      #    #  #  #     # 

This is a production mode. Do not copy paste anything here for security concerns.

Developer Credits:
shakalya195@gmail.com
amansharma5@gmail.com
roopalsaini78@gmail.com
vickysaija167@gmail.com

`);
// window.console.log=function(){};

}



platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

  