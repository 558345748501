<div *ngFor="let item of toasts" class="toast" 
(mouseenter)="item.mouseEnter()"
(mouseleave)="item.mouseLeave()"
>

    <span class="toast-img">
        <img src="assets/images/{{item?.type}}-active.svg" />
    </span>
    <div class="toast-body">
        <span class="toast-title">{{item?.title}}</span>
        <span *ngIf="item?.dateTitle" class="toast-small"><span class="toast-fade">{{item?.dateTitle}}</span>: {{item?.date}}</span>
        <span class="toast-desc">{{item?.desc}}</span>
    </div>
    <!-- <img *ngIf="item?.party" class="party" src="assets/images/party.gif" /> -->
    <!-- <audio *ngIf="item?.fire" style="visibility: none;" autoplay>
        <source src="assets/mp3/fire.mp3" type="audio/mpeg">
    </audio> -->
</div>
<canvas id="confetti" style="display: none;"></canvas>