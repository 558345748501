import { Injectable } from '@angular/core';
import * as confetti from 'canvas-confetti';

export class toastData{
  id:number;
  title:string;
  type:string;
  date:string;
  dateTitle:string;
  desc:string;
  timeout:any;
  mouseEnter:Object;
  mouseLeave:Object;
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toasts:any[]=[];
  n = 0;
  confetti:any;

  types = [
    'dashboard',
    'status',
    'quotes',
    'logs',
    'analytics',
    'announcements',
    'messages',
    'settings',
    'reorder',
  ];
  constructor() { 
    let wrapper = document.createElement("div");
    wrapper.setAttribute('id','toaster-wrapper');
    document.getElementsByTagName("body")[0].append(wrapper);
    this.confetti = confetti.create(document.getElementById('confetti'),{
      resize: true,
      useWorker: true
    });
  }

  toast(t:any){
    t.id = Date.now();
    t.timeout = setTimeout(()=>{
      let findIndex = this.toasts.findIndex(item=>item.id == t.id);
      this.toasts.splice(findIndex,1);
    },5000);
    t.mouseEnter = ()=>{
      console.log('time removed for',this.n);
      clearTimeout(t.timeout);
    }
    t.mouseLeave = ()=>{
      t.timeout = setTimeout(()=>{
        let findIndex = this.toasts.findIndex(item=>item.id == t.id);
        this.toasts.splice(findIndex,1);
      },3000);
    }
    if(t.fire){
      setTimeout(()=>{
        this.celebrate();
      },1);
      setTimeout(()=>{
        this.celebrate();
      },200)
    }
    this.toasts.unshift(t);
  }

  celebrate() {
    const duration = 3000; // in milliseconds
    console.log('confetti',confetti)
    // this.confetti();

    this.fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    this.fire(0.2, {
      spread: 60,
    });
    this.fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });
    this.fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });
    this.fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }

  fire(particleRatio, opts) {
    this.confetti({
      ...{origin: { y: 0.7 }},
      ...opts,
      particleCount: Math.floor(200 * particleRatio)
    });
  }

}
