import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams, HttpHeaders, HttpEventType } from '@angular/common/http';
import Swal from 'sweetalert2';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiPatientReorder {

  apiEndpoint:String = "";
  private data: any;

  user:any;
  private loginSource = new BehaviorSubject<any>({});
  user$ = this.loginSource.asObservable();

  // Show and hide Loader
  showLoader:boolean=true;
  private loaderSubject = new Subject<Boolean>();
  loaderState = this.loaderSubject.asObservable();
  loader(value){this.loaderSubject.next( value ); }

  enterpriseId:any;

  constructor(
    private http:HttpClient,
    private router:Router
  ) { 
    this.apiEndpoint = environment.API_PATIENT_REORDER_END_POINT;
  }


  setPatientData(data: any) {
    this.data = data;
  }

  getPatientData(): any {
    return this.data;
  }

  clearPatientData() {
    this.data = null;
  }

  getData(url,param){
    return this.http.get<any>(this.apiEndpoint+url,{params:param});
  }

  postData(url,value){
    // console.log(value);
    Object.keys(value).forEach(key => {
      if(!value[key] || value[key] === undefined )
      delete value[key];
    });
    console.log(value);
    return this.http.post<any>(this.apiEndpoint+url,value);
  }
  
  putData(url,value){
    return this.http.put<any>(this.apiEndpoint+url,value);
  }

  patchData(url,value){
    return this.http.patch<any>(this.apiEndpoint+url,value);
  }

  deleteData(url,param){
    return this.http.delete<any>(this.apiEndpoint+url,{params:param});
  }

  errorHandler(err){
    console.log(err);
  }

  swal(title,text,type){
    Swal.fire({
      title:title,
      text: text,
      icon: type,
      timer:2000
    });
  }

  updateLoginData(user){
    console.log('NEXT',user);
    this.user = user;
    this.loginSource.next(user);
  }

  logout(){
    Swal.fire({
      title: 'Are you sure?',
      // text: "You want to logout",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#f85b5c',
      cancelButtonColor: '#9393934d',
      confirmButtonText: 'Logout'
    }).then((result) => {
      if (result.value) {
        localStorage.removeItem('access_token');
        this.router.navigate(['/login']);
        this.user = null;
        this.loginSource.next(null);
        this.enterpriseId = undefined;
        (<any>window).Intercom('shutdown');
      }
    });
  }

    
}
